<template>
    <div class="up_content_inner up_manage_invoice">
        <div class="up_main_container">
            <div class="up_page_header p-jc-center">
                <h2 class="up_page_title">Change Password</h2>
            </div>
            <div class="up_inner_container">
                <div class="up_password_container">
                    <h3 class="p-mb-2">Enter Details</h3>
                    <form @submit.prevent="updatePassword()" class="up_password_update">
                        <div class="p-fluid p-formgrid p-grid">
                            <div class="p-field p-col-12">
                                <label for="oldPass">Old Password <span class="text-danger">*</span></label>
                                <Password  v-model="passwordState.oldPass" id="oldPass" toggleMask :feedback="false" />
                                <small class="p-invalid text-danger" v-if="passwordState.oldPassError">Please enter old password.</small>
                            </div>
                            <div class="p-field p-col-12">
                                <label for="newPass">New Password <span class="text-danger">*</span></label>
                                <Password  v-model="passwordState.newPass" id="newPass" toggleMask :feedback="false" />
                                <small class="p-invalid text-danger" v-if="passwordState.newPassError">Please enter new password.</small>
                            </div>
                            <div class="p-field p-col-12">
                                <label for="cnfNewPass">Confirm New Password<span class="text-danger">*</span></label>
                                <Password  v-model="passwordState.confNewPass" id="cnfNewPass" toggleMask :feedback="false" />
                                <small class="p-invalid text-danger" v-if="passwordState.confNewPassError">Please enter confirm password.</small>
                                <small class="p-invalid text-danger" v-if="passwordState.oldNewPassNotMatch">You can't use your previous password.</small>
                                <small class="p-invalid text-danger" v-if="passwordState.confNewPassNotMatch">New and Confirm password doesn't match.</small>
                            </div>
                            <div class="p-fluid p-col-12 up_button_center_align p-d-flex">                                
                                <Button icon="pi pi-check" type="submit" label="Update Password" class="p-button-primary  p-mr-2" />
                                <Button icon="pi pi-refresh" type="button" label="Reset" class="p-button-white-shadow" @click="resetProfileForm()" />
                            </div>
                        </div>
                        <div :class="['sup_form_massage p-mt-3', passwordState.status === 200 ? 'success' : 'error']" v-if="passwordState.status !== ''">{{passwordState.data}}</div >
                    </form>
                    <Toast />
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { userPortalChangePassword } from '../../../../config/appUrls';
// import customerLogout from '../../../../helper/userLogout/customerLogout';
import { apiKey } from "../../../../config/constant";
import axios from "axios";
import { useStore } from 'vuex';
import { reactive } from 'vue';
import { useToast } from "primevue/usetoast";

export default {
    name: "ChangePassword",
    setup(){
        //Application store call
        const store = useStore();

        //Toast Message
        const toast = useToast();

        const passwordState = reactive({
            status : '',
            data: '',
            oldPass: '',
            newPass: '',
            confNewPass: '',
            oldPassError: false,
            newPassError: false,
            confNewPassError: false,
            confNewPassNotMatch: false,
            oldNewPassNotMatch: false,
        });

        const updatePassword = async() => {
            let isValidate = updatePasswordValidation();
            if(isValidate){
                try{
                    const response = await axios.put( userPortalChangePassword, {
                        currentPassword: passwordState.oldPass,
                        newPassword: passwordState.newPass
                    }, {
                        headers: {
                            apiKey: apiKey,
                            token: store.getters.adminAuthToken
                        }, 
                    });
                    if (response.data.status === 200) {
                        passwordState.status = response.data.status;
                        passwordState.data = response.data.data;
                        toast.add({severity:'success', summary:'Success', detail:'Profile Updated Successfully', life: 3000});
                    }
                } catch(err){
                    passwordState.status = err.response.data.status;
                    passwordState.data = err.response.data.message;
                }
            }
        }

        const updatePasswordValidation = () => {
            if(passwordState.oldPass === ''){
                passwordState.oldPassError = true;
            } else {
                passwordState.oldPassError = false;
            }
            if(passwordState.newPass === ''){
                passwordState.newPassError = true;
            } else if (passwordState.newPass === passwordState.oldPass) {
                passwordState.newPassError = false;
                passwordState.oldNewPassNotMatch = true;
            } else {
                passwordState.newPassError = false;
                passwordState.oldNewPassNotMatch = false;
            }
            if(passwordState.confNewPass === ''){
                passwordState.confNewPassError = true;
            } else if (passwordState.confNewPass !== passwordState.newPass) {
                passwordState.confNewPassError = false;
                passwordState.confNewPassNotMatch = true;
            } else {
                passwordState.confNewPassError = false;
                passwordState.confNewPassNotMatch = false;
            }

            if (!passwordState.oldPassError && !passwordState.newPassError && !passwordState.confNewPassError && !passwordState.confNewPassNotMatch && !passwordState.oldNewPassNotMatch) {
                return true;
            } else {
                return false;
            }
        }

        const resetProfileForm = () => {
            passwordState.oldPass = '',
            passwordState.newPass = '',
            passwordState.confNewPass = ''
        }

        return{
            passwordState,
            updatePassword,
            resetProfileForm,
            city: null,
            categories: [
                {name: 'Accounting', key: 'A'}, 
                {name: 'Marketing', key: 'M'}, 
                {name: 'Production', key: 'P'}, 
                {name: 'Research', key: 'R'}
            ],
            selectedCategory: null
        }
    }
}
</script>

<style lang="scss" scoped>
.sup_form_massage.error {
  background-color: #ffdcdf;
  border-color: #e60017;
  color: #b30012;
}

.sup_form_massage.success {
  background-color: #c2fbd7;
  border-color: #0dbb4d;
  color: #0a8b39;
}
@import '../../../../assets/style/component/userportal/pages/changePassword/ChangePassword.scss';
</style>